export default {
    s3: {
        REGION: "us-east-2",
        BUCKET: "blog-app-uploads"
    },
    apiGateway: {
        REGION: "us-east-2",
        URL: "https://tv8jfdiaeg.execute-api.us-east-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-east-2",
        USER_POOL_ID: "us-east-2_7GdCK8hjw",
        APP_CLIENT_ID: "t2669d759r8tae1ioldl2u3em",
        IDENTITY_POOL_ID: "us-east-2:7e242f25-5404-4dac-af7a-1497e82d9df2"
    },
    MAX_ATTACHMENT_SIZE: 5000000,
};
