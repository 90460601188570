import React, { Component } from "react";
import { API } from "aws-amplify";
import {  ListGroup, Button, Alert , Accordion, Modal } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import { LinkContainer } from "react-router-bootstrap";
export default class ExamList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            exams: [],
            isModalActive:false,
            sectionActive:{}
        };
    }
    handleClose =()=> {
        this.setState({isModalActive: false});
    }

    // handleShow = (section)=>{
    //     console.log("Esto es genial", section)
    //     this.setState({isModalActive: true});
    // }


    handleShow = (section) => e => {
        console.log("Esto es genial", section)
        this.setState({isModalActive: true, sectionActive:section });
        // param is the argument you passed to the function
        // e is the event object that returned
    };


    clickCertification  () {

        console.log("Se hizo click");

    }


    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            const exams = await this.exams();
            console.log("Estas son las notes", exams)
            this.setState({ exams });
        } catch (e) {
            alert(e);
        }

        this.setState({ isLoading: false });


    }
    redirect(newRoute){
        this.props.history.push(newRoute);
    }
    exams() {
        return API.get("notes", "/exams");
    }

    renderLander() {
        return (
            <div className="lander">
                <h1>Scratch</h1>
                <p>A simple note taking app</p>
            </div>
        );
    }
    renderExamsList(exams) {
        return (
            <Accordion  defaultActiveKey="0">
            {exams.map((exam, i) =>
                <Card key = {exam.name}>
                    <Card.Header>
                    <Accordion.Toggle as={Card.Header} eventKey={i} className="d-flex justify-content-between align-items-center">
                        <div>
                        {exam.name.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1").replace("a W","AW" )}
                        </div>
                        <Button variant="primary" onClick={()=>this.redirect(`/exams/${exam.name}`)}> Take Exam</Button>    
                    </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={i}>
                        <Card.Body>
                        <ListGroup>
                            {exam.sections.map((section, j)=>
                            
                            <ListGroup.Item key= {`${section.alias}${j}`} className= "d-flex justify-content-between align-items-center">
                                <div>
                                {`${section.title} (${section.percentage})`}

                                </div>
                       
                                <Button variant="primary" onClick={this.handleShow(section)}>
                                    Ver detalle 
                                </Button>

                         
                            </ListGroup.Item>
                            
                            )}
                        </ListGroup>
                        </Card.Body>
 
                    </Accordion.Collapse>
                </Card>
            )}
            </Accordion>
            // <LinkContainer
                //     key={exam.name}
                //     to={`/exams/${exam.name}`}
                // >
                //     <div>{exam.name}</div>
                // </LinkContainer>
            )
        
    }

    renderExams() {
        return (
            <div className="notes">
                {/* <PageHeader>Your Notes</PageHeader> */}
                <ListGroup>
                    {!this.state.isLoading && this.renderExamsList(this.state.exams)}
                </ListGroup>
                <Modal show={this.state.isModalActive} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>{this.state.sectionActive.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.sectionActive.description}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onCliconClick={this.handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={this.handleClose}>
                        Save Changes
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated ? this.renderExams() : this.renderLander()}
            </div>
        );
    }


}
