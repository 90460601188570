import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import {  Card , ListGroup,ListGroupItem,  Button, Alert , Accordion, Modal } from "react-bootstrap";
import marked from "marked";
import config from "../config";
import "./Notes.css";
import { s3Upload } from "../libs/awsLib";
import ReactDOM from 'react-dom'
import MdEditor from 'react-markdown-editor-lite'
import MarkdownIt from 'markdown-it'


export default class EditArticle extends Component {
    constructor(props) {
        super(props);

        this.file = null;

        this.state = {
            isLoading: null,
            isDeleting: null,
            title:'',
            category:'',
            note: null,
            content: "",
            attachmentURL: null,
            profile:{},
            linkedinRoute: '',
            canShowProfile: false,
            mdText:'',
            mdHtml:'',
            mdParser:new MarkdownIt()
        };
    }

    async componentDidMount() {
        try{
            let articleId = this.props.match.params.id;
            let article = await API.post("blog", `/blog/article/get`, {
                body:{
                    id: articleId
                }
            });
            console.log("Este es el article", article)
            this.setState({...this.state,
                title: article.result.title || '',
                category: article.result.category || '',
                mdText:article.result.content,
                id:articleId })

        }catch(e){
            console.log("Esto es e", e)
        }

    }

    handleEditorChange =  () => async e => {
        console.log('handleEditorChange', e)
        let nenitas = this.state.mdParser.render(this.state.mdText)
        console.log("Esta es nenitas", nenitas)
        // console.log("Veamos el state", this.state)
            this.setState({
                ...this.state, 
                mdText: e.text,
                mdHtml:e.html
              })
    }

    saveArticle = () => async e => {
        console.log("this.state.mdText", this.state.mdText)
        let article = {
            content: this.state.mdText,
            name: "Titulo 1"
        }
        try{

            let element = await API.post("blog", "/blog/article/create", {
                body: article
            });
            console.log("element", element)

        }catch(e){

        }


    }

    createArticle(article){
        return API.post("blog", "/blog/article/create", {
            body: article
        });
    }

    handleImageUpload =  () => async e => {
        console.log("Este es mi e", e)
        // const reader = new FileReader()
        // reader.onload = () => {      
        //   const convertBase64UrlToBlob = (urlData) => {  
        //     let arr = urlData.split(','), mime = arr[0].match(/:(.*?);/)[1]
        //     let bstr = atob(arr[1])
        //     let n = bstr.length
        //     let u8arr = new Uint8Array(n)
        //     while (n--) {
        //       u8arr[n] = bstr.charCodeAt(n)
        //     }
        //     return new Blob([u8arr], {type:mime})
        //   }
        //   const blob = convertBase64UrlToBlob(reader.result)
        //   setTimeout(() => {
        //     // setTimeout 模拟异步上传图片
        //     // 当异步上传获取图片地址后，执行calback回调（参数为imageUrl字符串），即可将图片地址写入markdown
        //     callback('https://avatars0.githubusercontent.com/u/21263805?s=40&v=4')
        //   }, 1000)
        // }
        // reader.readAsDataURL(file)
    }


    syncUpProfile = () =>async e =>{
        try {
            const myProfile = await API.post("talent", `/linkedin`, {
                body:{
                    linkedinProfile: this.state.linkedinRoute
                }
            })
            console.log("Estos son mis note", myProfile)
            this.setState({...this.state, profile: myProfile.employee, canShowProfile:true }) 
        } catch (e) {
            alert(e);
        }

    }

    getNote() {
        return API.get("notes", `/notes/${this.props.match.params.id}`);
    }


    validateForm() {
        return this.state.content.length > 0;
    }

    formatFilename(str) {
        return str.replace(/^\w+-/, "");
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleFileChange = event => {
        this.file = event.target.files[0];
    }

    saveNote(note) {
        return API.put("notes", `/notes/${this.props.match.params.id}`, {
            body: note
        });
    }

    handleSubmit = async event => {
        let articleObject = {
            title:this.state.title,
            category: this.state.category,
            content: this.state.mdText
        }
        console.log("articleObject", articleObject)
        let attachment;

        event.preventDefault();
        console.log("Se va a analizar")


        this.setState({...this.state ,isLoading: true });
        let article = {
            id:this.state.id,
            content: this.state.mdText,
            title:this.state.title,
            category: this.state.category
        }

        try {
            let element = await API.post("blog",
             "/blog/article/update", {
                body: article
            });

            this.props.history.push("/");
        } catch (e) {
            alert(e);
            this.setState({ ...this.state, isLoading: false });
        }



    }



    deleteNote() {
        return API.del("notes", `/notes/${this.props.match.params.id}`);
    }

    handleDelete = async event => {
        event.preventDefault();

        const confirmed = window.confirm(
            "Are you sure you want to delete this note?"
        );

        if (!confirmed) {
            return;
        }

        this.setState({ isDeleting: true });

        try {
            await this.deleteNote();
            this.props.history.push("/");
        } catch (e) {
            alert(e);
            this.setState({ isDeleting: false });
        }
    }


    render() {
        console.log("this.state", this.state)
        console.log("Que bello ha cambiado" , this.state.profile.profilePicture)
        // const { markdown } = this.state;
        return (
            <div className="Notes">
            <form onSubmit={this.handleSubmit}>
                <Form.Group controlId="title">
                    <Form.Label>Título del articulo</Form.Label>
                    <Form.Control
                        autoFocus
                        type="text"
                        value={this.state.title}
                        onChange={this.handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="category">
                    <Form.Label>Categoría</Form.Label>
                    <Form.Control
                        autoFocus
                        type="text"
                        value={this.state.category}
                        onChange={this.handleChange}
                    />
                </Form.Group>
                <section style = {{height: '500px'}}>
                        <MdEditor
                        style = {{height: '400px'}}
                        value={this.state.mdText}
                        renderHTML={(text) => this.state.mdParser.render(text)}
                        onChange={this.handleEditorChange()}
                        onImageUpload={this.handleImageUpload()}
                        />                
                </section>
                <Button variant="primary" type="submit" >
                        Editar articulo
                </Button>


            </form>
            {/* onClick={this.saveArticle()} */}
                    {/* <Button variant="primary" onClick={this.saveArticle()}>
                        Guardar articulo
                    </Button>
                    <section style = {{height: '500px'}}>
                        <MdEditor
                        style = {{height: '400px'}}
                        value={this.state.mdText}
                        renderHTML={(text) => this.state.mdParser.render(text)}
                        onChange={this.handleEditorChange()}
                        onImageUpload={this.handleImageUpload()}
                        />                
                    </section> */}
            </div>
        );
    }
}
