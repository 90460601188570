import React, { Component } from "react";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import marked from "marked";
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  ListGroupItem,
  Button,
  Alert,
  Accordion,
  Modal
} from "react-bootstrap";

import "./PublicArticle.css";
import LinkedinSDK from "react-linkedin-sdk";

export default class ListPublicArticles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      mdHtml: "",
      mdText: "",
      articles: [],
      laboratories: [
        {
          title: "Linux Lab",
          description:
            "You could get skills to bash, administrative and another things",
          img: "https://picsum.photos/id/1001/100/100?blur=5"
        },
        {
          title: "AWS Lab",
          description:
            "You could get skills to bash, administrative and another things",
          img: "https://picsum.photos/id/1002/100/100?blur=5"
        },
        {
          title: "Visual Studio Code",
          description:
            "You could get skills to bash, administrative and another things",
          img: "https://picsum.photos/id/1003/200/200?blur=5"
        },
        {
          title: "Node.js",
          description: "Makes your life easy",
          img: "https://picsum.photos/id/1004/200/200?blur=5"
        },
        {
          title: "Puppeteer.js",
          description: "Makes your life easy",
          img: "https://picsum.photos/id/1005/200/200?blur=5"
        }
      ]
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      try {
        let articleId = this.props.match.params.id;
        let articles = await API.post("blog", `/blog/articles`, {
          body: {}
        });
        let articlesModified = {};
        articles.result.forEach(item => {
          if (!Object.keys(articlesModified).includes(item.category)) {
            articlesModified[item.category] = [];
          }
          articlesModified[item.category].push(item);
        });
        console.log("Este es el article", articlesModified);
        // let text = article.result.content || ''

        this.setState({
          ...this.state,
          // mdHtml: marked(text),
          articles: articlesModified
        });
      } catch (e) {}
    } else {
      this.props.history.push("/list-articles");
    }
  }

  responseLinkedin = event => {
    console.log("ALgo esta pasando");
  };

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.signIn(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };
  redirect(newRoute) {
    this.props.history.push(newRoute);
  }

  renderPublicArticles() {
    return (
      <div className="PublicArticle">
        <Accordion defaultActiveKey="0">
          {Object.keys(this.state.articles).map((item, i) => (
            <ul key={i}>
              <li>
                <Accordion.Toggle
                  as={Card.Header}
                  className="accordion-toggle"
                  eventKey={i}
                >
                  {item}
                </Accordion.Toggle>

                <Accordion.Collapse eventKey={i}>
                  <ul>
                    {this.state.articles[item].map((ele, j) => (
                      <li
                        key={`sub-${j}`}
                        className="accordion-toggle"
                        onClick={() =>
                          this.redirect(
                            `/article/${ele.userId}--${ele.articleId}`
                          )
                        }
                      >
                        {ele.title}
                      </li>
                    ))}
                  </ul>
                </Accordion.Collapse>
              </li>
            </ul>
          ))}
        </Accordion>
      </div>
    );
  }

  renderMyListArticles() {
    this.props.history.push("/list-articles");
    // return (
    //     <div className="MyListArticles">
    //         <span>Hello</span>
    //     </div>
    // )
  }

  render() {
    return (
      <div className="ListPublicArticles">
        <Container>
          <Row>
            {this.state.laboratories.map((item, i) => (
              <Col key={i} className="laboratory-item">
                <Card style={{ width: "18rem" }}>
                  <Card.Img variant="top" src={item.img} />
                  <Card.Body>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>{item.description}</Card.Text>
                    <Button
                      variant="primary"
                      onClick={() =>
                        this.redirect(
                          `/article/us-east-2:a7944ede-cd76-401a-ad0a-ef32561293bd--1317b040-1002-11ea-82e5-59ca258cea71`
                        )
                      }
                    >
                      Ver
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    );
  }
}
