import React, { Component, Fragment } from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "./Routes";
import "./App.css";
import { Auth } from "aws-amplify";


class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };

  }
  handleLogout = async  event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    }
    catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
    console.log("Esto esta chill")
    this.setState({ isAuthenticating: false });

    console.log("Claro pues nena")
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      !this.state.isAuthenticating &&
      <div className="App container">

        <Navbar bg="light" expand="lg">
          <Navbar.Brand>
            <Link to="/">AWS BLOG</Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {this.state.isAuthenticated
                ?
                <Fragment>

                  <Nav.Item onClick={this.handleLogout}>Logout</Nav.Item>

                </Fragment>
                :
                <Fragment>
                  <LinkContainer className="nav-item" to="/login">
                    <Nav.Item>Login</Nav.Item>
                  </LinkContainer>
                  <LinkContainer className="nav-item" to="/signup">
                    <Nav.Item>Signup</Nav.Item>
                  </LinkContainer>
                </Fragment>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <Routes childProps={childProps} />
      </div>
    );
  }


}
export default withRouter(App);



