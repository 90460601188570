import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { Card, ListGroup, ListGroupItem, Button, Alert, Accordion, Modal } from "react-bootstrap";
import marked from "marked";
import config from "../config";
import "./Notes.css";
import { s3Upload } from "../libs/awsLib";
import ReactDOM from 'react-dom'
import MdEditor from 'react-markdown-editor-lite'
import MarkdownIt from 'markdown-it'
import "./Menu.css";
const MOCK_DATA = "Hello.\n\n * This is markdown.\n * It is fun\n * Love it or leave it."

export default class EditPage extends Component {
    constructor(props) {
        super(props);

        this.file = null;

        this.state = {
            articles: [],
            isLoading: null,
            isDeleting: null,
            note: null,
            content: "",
            attachmentURL: null,
            profile: {},
            linkedinRoute: '',
            canShowProfile: false,
            mdText: '',
            mdHtml: '',
            mdParser: new MarkdownIt()
        };
    }

    async componentDidMount() {
        try {
            let articleList = await API.post("blog", `/blog/article/list`, {});
            console.log("list", articleList)
            this.setState({ ...this.state, articles: articleList.result })
        } catch (e) {
            console.log("e", e)

        }
    }

    handleEditorChange = () => async e => {
        console.log('handleEditorChange', e)
        let nenitas = this.state.mdParser.render(this.state.mdText)
        console.log("Esta es nenitas", nenitas)
        // console.log("Veamos el state", this.state)
        this.setState({
            ...this.state,
            mdHtml: e.html
        })
    }

    goNewRoute = async event => {
        this.props.history.push("/new-article");
    }
    handleEditArticle = (id) => async e => {
        this.props.history.push(`/edit-article/${id}`);
    }
    handleDeleteArticle = (id) => async e => {
        let response = await API.post("blog", `/blog/article/delete`, {
            body: {
                id: id
            }
        });

        if (response.status) {
            let newArticles = this.state.articles.filter(item => item.articleId !== id)
            this.setState({ ...this.state, articles: newArticles })
        }
        // let nenitas = this.state.mdParser.render(this.state.mdText)
        // console.log("Esta es nenitas", nenitas)
        // // console.log("Veamos el state", this.state)
        //     this.setState({
        //         ...this.state, 
        //         mdText: e.text,
        //         mdHtml:e.html
        //       })
    }



    render() {
        // const { markdown } = this.state;
        return (
            <div className="Articles">
                <Button variant="primary" onClick={this.goNewRoute}>
                    Generate new article
                    </Button>
                <div >
                    {this.state.articles.map((articleItem, j) =>

                        <Card key={`${articleItem.userId}--${articleItem.articleId}`}>
                            <Card.Body>
                                <Card.Title>{articleItem.title || ''}</Card.Title>
                                <Button variant="primary" onClick={this.handleEditArticle(articleItem.articleId)}>Editar</Button>
                                <Button variant="danger" onClick={this.handleDeleteArticle(articleItem.articleId)} >Eliminar</Button>
                            </Card.Body>
                        </Card>

                        // <div key={j} > {`${articleItem.userId}--${articleItem.articleId}`} </div>
                    )}
                </div>
            </div>
        );
    }
}
