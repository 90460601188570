import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import ExamList from "./containers/ExamList/ExamList";
import ExamQuestions from "./containers/ExamQuestions/ExamQuestions";

import AppliedRoute from "./components/AppliedRoute";
import Signup from "./containers/Signup";
import NewNote from "./containers/NewNote";
import Notes from "./containers/Notes";
import PersonalFinance from "./containers/PersonalFinance/PersonalFinance";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import MyProfile from "./containers/MyProfile";
import Edit from "./containers/Edit";
import ListArticles from "./containers/ListArticles";

import AddNewArticle from "./containers/AddNewArticle";

import PublicArticle from "./containers/PublicArticle";

import Administrator from "./containers/Administrator";
import EditArticle from "./containers/EditArticle";
import ListPublicArticles from "./containers/ListPublicArticles";




export default ({ childProps }) =>
    <Switch>
        <AppliedRoute path="/" exact component={ListPublicArticles} props={childProps} />

        <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
        <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
        <UnauthenticatedRoute path="/article/:id" exact component={PublicArticle} props={childProps} />
        {/* <UnauthenticatedRoute path="/list-articles" exact component={PublicArticle} props={childProps} /> */}


        <AuthenticatedRoute path="/list-articles" exact component={ListArticles} props={childProps} />
        <AuthenticatedRoute path="/new-article" exact component={AddNewArticle} props={childProps} />
        <AuthenticatedRoute path="/edit-article/:id" exact component={EditArticle} props={childProps} />


        <AuthenticatedRoute path="/public/page/:id" exact component={Edit} props={childProps} />

        <AuthenticatedRoute path="/private/page/:id" exact component={Edit} props={childProps} />
        {/* <AuthenticatedRoute path="/private/new-page/" exact component={NewArticle} props={childProps} /> */}

        <Route component={NotFound} />
    </Switch>;

