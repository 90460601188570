import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import {  Card , ListGroup,ListGroupItem,  Button, Alert , Accordion, Modal } from "react-bootstrap";
import marked from "marked";
import config from "../config";
import "./Notes.css";
import { s3Upload } from "../libs/awsLib";


export default class MyProfile extends Component {
    constructor(props) {
        super(props);

        this.file = null;

        this.state = {
            isLoading: null,
            isDeleting: null,
            note: null,
            content: "",
            attachmentURL: null,
            profile:{},
            linkedinRoute: '',
            canShowProfile: false
        };
    }

    async componentDidMount() {
        const readmePath = require("../files/basico.md");
        fetch(readmePath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          this.setState({
            ...this.state,
            markdown: marked(text)
          })
        })
        // try {
        //     let attachmentURL;
        //     const note = await this.getNote();
        //     const { content, attachment } = note;

        //     if (attachment) {
        //         attachmentURL = await Storage.vault.get(attachment);
        //     }

        //     this.setState({
        //         note,
        //         content,
        //         attachmentURL
        //     });
        // } catch (e) {
        //     alert(e);
        // }
    }

    syncUpProfile = () =>async e=>{
        try {
            const myProfile = await API.post("talent", `/linkedin`, {
                body:{
                    linkedinProfile: this.state.linkedinRoute
                }
            })
            console.log("Estos son mis note", myProfile)
            this.setState({...this.state, profile: myProfile.employee, canShowProfile:true }) 
        } catch (e) {
            alert(e);
        }

    }

    getNote() {
        return API.get("notes", `/notes/${this.props.match.params.id}`);
    }


    validateForm() {
        return this.state.content.length > 0;
    }

    formatFilename(str) {
        return str.replace(/^\w+-/, "");
    }

    handleChange = event => {
        this.setState({
            ["linkedinRoute"]: event.target.value
        });
    }

    handleFileChange = event => {
        this.file = event.target.files[0];
    }

    saveNote(note) {
        return API.put("notes", `/notes/${this.props.match.params.id}`, {
            body: note
        });
    }

    handleSubmit = async event => {
        let attachment;

        event.preventDefault();

        if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
            alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000} MB.`);
            return;
        }

        this.setState({ isLoading: true });

        try {
            if (this.file) {
                attachment = await s3Upload(this.file);
            }

            await this.saveNote({
                content: this.state.content,
                attachment: attachment || this.state.note.attachment
            });
            this.props.history.push("/");
        } catch (e) {
            alert(e);
            this.setState({ isLoading: false });
        }
    }



    deleteNote() {
        return API.del("notes", `/notes/${this.props.match.params.id}`);
    }

    handleDelete = async event => {
        event.preventDefault();

        const confirmed = window.confirm(
            "Are you sure you want to delete this note?"
        );

        if (!confirmed) {
            return;
        }

        this.setState({ isDeleting: true });

        try {
            await this.deleteNote();
            this.props.history.push("/");
        } catch (e) {
            alert(e);
            this.setState({ isDeleting: false });
        }
    }


    renderProfile() {
        return (
            <div className="my-profile">
            <Card style={{ width: '18rem' }}>
                <Card.Img variant="top" src={this.state.profile.profilePicture || 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/85/Smiley.svg/220px-Smiley.svg.png'} />
                <Card.Body>
                    <Card.Title>{this.state.profile.profileName}</Card.Title>
                    <Card.Text>
                    {this.state.profile.profileDescription}
                    </Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush">
                    <ListGroupItem>
                        
                        Cras justo odio
                        
                    </ListGroupItem>
                    <ListGroupItem>Dapibus ac facilisis in</ListGroupItem>
                    <ListGroupItem>Vestibulum at eros</ListGroupItem>
                </ListGroup>
                <Card.Body>
                    <Card.Link href="#">Card Link</Card.Link>
                    <Card.Link href="#">Another Link</Card.Link>
                </Card.Body>
            </Card>    

            </div>
        )


    }
    render() {
        console.log("this.state", this.state)
        console.log("Que bello ha cambiado" , this.state.profile.profilePicture)
        const { markdown } = this.state;
        return (
            <div className="Notes">
                <section>
                    <article dangerouslySetInnerHTML={{__html: markdown}}></article>
                </section>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="email">
                        <Form.Label>Ruta de linkedin</Form.Label>
                        <Form.Control
                            autoFocus
                            type="text"
                            value={this.state.linkedinRoute}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Button variant="primary" onClick={this.syncUpProfile()}>
                        Buscar en linkedin
                    </Button>
                </Form>
                {this.state.canShowProfile ? this.renderProfile() : null}
            </div>
        );
    }
}
