import React, { Component } from "react";
import { API } from "aws-amplify";
import { ListGroup, Card, Button, Form, Badge, Pagination } from "react-bootstrap";

export default class ExamQuestions extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: true,
      isDeleting: null,
      note: null,
      content: "",
      attachmentURL: null,
      questions: [],
      activeNumber:0,
      activeQuestion: {
        description: "",
        alternatives: []
      }
    };
  }

  handleChange = alternative => event => {
    console.log("alternative", alternative);
    
    this.state.questions[this.state.activeNumber].alternatives.forEach(item => {
      if (item.text == alternative.text) {
        console.log("item.num", item.num);
    //    alternative.checkedByUser = event.target.checked;
        item.checkedByUser = event.target.checked
       
      }
    });
    this.setState({questions:this.state.questions})
    console.log(
      "event.target.id",
      event.target.id,      
      "event.target.value",
      event.target.checked
    );
    // this.setState({
    //     [event.target.id]: event.target.value
    // });
  };

 changeQuestion = (questionNumber) =>e =>{
    console.log("questionNumber", questionNumber);
    // let newQuestion = this.state.questions.filter(item=>item.num == questionNumber)
    this.setState({activeNumber:questionNumber})

  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const questions = await this.getNote();

      questions.forEach(item => {
          item.alternatives.forEach(element => {
              element.checkedByUser = false;
            });
        });
      this.setState({ questions, activeQuestion: questions[0] });
      console.log("Estas son las questions", questions);
      // this.setState({ questions });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  getNote() {
    console.log(
      "Esto traemos ",
      `/exams/${this.props.match.params.id}/questions`
    );
    return API.get("notes", `/exams/${this.props.match.params.id}/questions`);

    // let sections = ["Define Performance Architectures",
    // "Design Cost-optimized Architectures",
    // "Design Resilient Architectures",
    // "Specify Secure Applications and Architectures",
    // "Define Operationally Excellent Architectures" ]
  }
  saveQuestion = event => {
    console.log(this.state);

    // let regularQuestion = this.state.questions.filter(item=>item.num == this.state.activeQuestion.num)
    // regularQuestion[0] = this.state.activeQuestion
    // this.setState({questions:this.state.questions})

  };

  renderAlternatives(activeQuestion){
      console.log("Se acaba de renderizar", activeQuestion)
    return(
        <ListGroup variant="flush">
        {activeQuestion.alternatives.map(
          (alternative, j) => (
            // <span key={`${j}`}>{alternative.text}</span>
            // <div key= {j}>

            <ListGroup.Item key={j}>
              <Form.Group controlId={j}>
                <Form.Check
                  type="checkbox"
                  label={alternative.text}
                  checked={alternative.checkedByUser}
                //   value={alternative.checkedByUser}
                  onChange={this.handleChange(alternative)}
                />
              </Form.Group>
            </ListGroup.Item>
          )

          // </div>
        )}
      </ListGroup>

    )

  }
  
  renderQuestionsList(questions) {
    return (
    <Form>
      <Card className="text-center">
        <Card.Header>{`Pregunta ${this.state.activeNumber +
          1}`}
            <div className="d-flex justify-content-center align-items-center">
            {this.state.questions[this.state.activeNumber].tags.map(tag => (
                <Badge key = {tag} pill variant="primary" className="m-1">
                {tag}
              </Badge>
            ))}
          </div>
          </Card.Header>
        <Card.Body>
          <Card.Title>{this.state.questions[this.state.activeNumber].description}</Card.Title>
            {this.renderAlternatives(this.state.questions[this.state.activeNumber])}
            {/* <ListGroup variant="flush">
              {this.state.questions[this.state.activeNumber].alternatives.map(
                (alternative, j) => (
                  // <span key={`${j}`}>{alternative.text}</span>
                  // <div key= {j}>

                  <ListGroup.Item key={j}>
                    <Form.Group controlId={j}>
                      <Form.Check
                        type="checkbox"
                        label={alternative.text}
                        value={alternative.checkedByUser}
                        onChange={this.handleChange(alternative)}
                      />
                    </Form.Group>
                  </ListGroup.Item>
                )

                // </div>
              )}
            </ListGroup> */}

          <Button variant="primary" onClick= {this.saveQuestion}>
            Save Answer
          </Button>
        </Card.Body>
        <Card.Footer className="text-muted">
          <div className="d-flex justify-content-center align-items-center"> 
            <Pagination>
                <Pagination.Prev disabled={this.state.activeNumber == 0} onClick={this.changeQuestion(this.state.activeNumber-1)}/>
                <Pagination.Item active>{this.state.activeNumber+1}</Pagination.Item>
                <Pagination.Next onClick={this.changeQuestion(this.state.activeNumber+1)}/>
            </Pagination>
          </div>
        </Card.Footer>
      </Card>
    </Form>
    );

    return questions.map((question, i) => (
      // <LinkContainer
      //     key={exam.name}
      //     to={`/exam/${exam.name}`}
      // >
      //     {/* <ListGroupItem header={note.content.trim().split("\n")[0]}>
      //             {"Created: " + new Date(note.createdAt).toLocaleString()}
      //         </ListGroupItem> */}
      //     <div>{exam.name}</div>
      // </LinkContainer>

      <div key={i}>
        <div>{question.description}</div>
        {question.alternatives.map((alternative, j) => (
          <span key={`${i}-${j}`}>{alternative.text}</span>
        ))}
      </div>
    ));
  }

  renderQuestions() {
    return (
      <div className="notes">
        {/* <PageHeader>Your Questions</PageHeader> */}
        <ListGroup>
          {!this.state.isLoading &&
            this.renderQuestionsList(this.state.questions)}
        </ListGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderQuestions() : null}
      </div>
    );
  }
}
