import React, { Component } from "react";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import marked from "marked";
import ReactMarkdown from 'react-markdown';
import { Container, Row, Col, Card, ListGroup, ListGroupItem, Button, Alert, Accordion, Modal } from "react-bootstrap";
import LateralMenu from '../components/LateralMenu'; // Import a component from another file


// const ReactMarkdown = require('react-markdown')

import "./PublicArticle.css";
import LinkedinSDK from 'react-linkedin-sdk'

export default class PublicArticle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            email: "",
            password: "",
            mdHtml: '',
            mdText: '',
            articles: [],
            paramsId:''

        };
    }

    async componentDidMount() {


        try {
            let articles = await API.post("blog", `/blog/articles`, {
                body: {}
            });
            let articlesModified = {}
            articles.result.forEach(item => {
                if (!Object.keys(articlesModified).includes(item.category)) {
                    articlesModified[item.category] = []
                }
                articlesModified[item.category].push(item)
            })
            let articleId = this.props.match.params.id;
            let article = await API.post("blog", `/blog/article/show`, {
                body: {
                    id: articleId
                }
            });
            console.log("Este es el article", article)
            let text = article.result.content || ''
            console.log("Este es mi text", text)

            this.setState({
                ...this.state,
                mdText: text,
                mdHtml: marked(text),
                articles: articlesModified,
                paramsId:articleId

            })

        } catch (e) {



        }


    }
    async componentWillReceiveProps(nextProps) {
        console.log("Esto es cool", nextProps.match.params.id)
        let articleId = nextProps.match.params.id;
        let article = await API.post("blog", `/blog/article/show`, {
            body: {
                id: articleId
            }
        });
        console.log("Este es el article", article)
        let text = article.result.content || ''
        this.setState({...this.state,paramsId:nextProps.match.params.id, mdText: text })
        
    }

    responseLinkedin = event => {
        console.log("ALgo esta pasando")
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            await Auth.signIn(this.state.email, this.state.password);
            this.props.userHasAuthenticated(true);

        } catch (e) {
            alert(e.message);
            this.setState({ isLoading: false });
        }
    }



    render() {
        return (
            <div className="PublicArticle" id={this.state.paramsId}>
                <section>
                    <Container>
                        <Row>
                            <Col sm={12} md={4}>
                            <LateralMenu history={this.props.history} articles = {this.state.articles}/>
                            </Col>
                            <Col sm={12} md={8}>
                                <ReactMarkdown source={this.state.mdText} />
                            </Col>

                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}
