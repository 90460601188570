
import React, { Component } from "react";
import { Card, ListGroup, ListGroupItem, Button, Alert, Accordion, Modal } from "react-bootstrap";
import "./LoaderButton.css";

class LateralMenu extends Component {
    constructor(props) {
        super(props);
    }
    redirect(newRoute) {
        this.props.history.push(newRoute);
    }

  render() {
    return (
      <div className="Menu">
        <Accordion defaultActiveKey="0">
          {Object.keys(this.props.articles).map((item, i) => (
            <ul key={i}>
              <li>
                <Accordion.Toggle
                  as={Card.Header}
                  className="accordion-toggle"
                  eventKey={i}
                >
                  {item}
                </Accordion.Toggle>

                <Accordion.Collapse eventKey={i}>
                  <ul>
                    {this.props.articles[item].map((ele, j) => (
                      <li
                        key={`sub-${j}`}
                        className="accordion-toggle"
                        onClick={() =>
                          this.redirect(
                            `/article/${ele.userId}--${ele.articleId}`
                          )
                        }
                      >
                        {ele.title}
                      </li>
                    ))}
                  </ul>
                </Accordion.Collapse>
              </li>
            </ul>
          ))}
        </Accordion>
      </div>
    );
  }
}
export default LateralMenu; // Don’t forget to use export default!
